<template>
  <div></div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import constants from "../../constants.json";
export default {
  mounted() {
    this.updateUserWorkingStatus();
  },
  methods: {
    updateUserWorkingStatus() {
      let url = `${constants.SERVER_API}updateWorkingStatus`;
      let params = {
        status: "stopped",
      };
      console.log(params);
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          // this.handleNotification(response);
          if (response.data.status === "success") {
            // localStorage.setItem("userAccessToken", '');
            localStorage.removeItem("userAccessToken"); 
            localStorage.setItem("role", "");
            console.log("Logged out");
            router.push("/allleads");
            // if (status === "stopped") {
            //   router.push("/logout");
            // } else if (status === "paused") {
            //   router.push("/resumeWork");
            // }
          } else {
            this.handleNotification(response);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style></style>
